import { subject } from '@casl/ability'
import { Text } from '@chakra-ui/react'
import type { ChangeEvent, FC } from 'react'
import { useCallback } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Can from '@app/shared/authorization/can'
import { SelectInput } from '@app/shared/rawForms'
import useToast from '@app/shared/toast'
import { RoleEnum, useAccountUpdateMutation } from '@graphql/queries'
import type { Account } from '@graphql/types'

interface Props {
  data: Account
}

const RoleCell: FC<Props> = ({ data: account }) => {
  const { user } = useStoreCurrentUser()
  const { id, role, disabledAt } = account
  const toast = useToast()
  const [, updateRole] = useAccountUpdateMutation()

  const onChange = useCallback(
    async (e: ChangeEvent<HTMLSelectElement>) => {
      await updateRole({
        input: { accountId: id, role: e.target.value as RoleEnum }
      })
      toast({ title: 'Updated account' })
    },
    [id, toast]
  )

  if (disabledAt) {
    return <Text color={disabledAt ? 'gray.400' : 'gray.700'}>{role === RoleEnum.Viewer ? 'Viewer' : 'Editor'}</Text>
  }

  return (
    <Can I="update" this={subject('account', { id, organizationId: user?.organization?.id })} passThrough>
      {(allowed) => (
        <SelectInput defaultValue={role} onChange={onChange} isDisabled={!allowed} name="role" placeholder="">
          <option value={RoleEnum.Viewer}>Viewer</option>
          <option value={RoleEnum.Editor}>Editor</option>
        </SelectInput>
      )}
    </Can>
  )
}

export default RoleCell
