import { Box, useColorMode, useColorModeValue } from '@chakra-ui/react'
import type { BoxProps } from '@chakra-ui/react'
import type { HandleComponentProps } from '@xyflow/react'
import { Handle, Position } from '@xyflow/react'
import type { FC, ReactNode, RefObject } from 'react'
import { memo } from 'react'

import useAreNodesSelected from '@app/hooks/useAreNodesSelected'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { NON_SELECTED_DIMMING_OPACITY } from '@app/lib/globals'
import type { MapDomainNode } from '@app/types'

export const handleStyles = (canEdit, size = 18, colorMode = 'light') => ({
  opacity: canEdit ? '1' : '0',
  backgroundColor: colorMode === 'light' ? '#fff' : '#ddd',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: colorMode === 'light' ? '#cbd5e0' : '#eee',
  width: `${size}px`,
  height: `${size}px`
})

interface Props extends BoxProps {
  topHandle?: Omit<HandleComponentProps, 'position'>
  bottomHandle?: Omit<HandleComponentProps, 'position'>
  leftHandle?: Omit<HandleComponentProps, 'position'>
  rightHandle?: Omit<HandleComponentProps, 'position'>
  children: ReactNode

  nodeRef: RefObject<HTMLDivElement>
  node: MapDomainNode
}

const Node: FC<Props> = ({ topHandle, bottomHandle, leftHandle, rightHandle, children, nodeRef, node, ...rest }) => {
  const { user } = useStoreCurrentUser()
  const isEditor = user?.role === 'editor'

  const { strategyId } = node
  const { colorMode } = useColorMode()
  const areNodesSelected = useAreNodesSelected(strategyId)
  const selectedNodeColor = useColorModeValue('blue.400', 'blue.600')

  const size = node?.metadata?.size || 'normal'
  const defaultMinW = 350
  const defaultMaxW = 500
  const minW = size === 'normal' ? `${defaultMinW}px` : `${defaultMinW * 1.3}px`
  const maxW = size === 'normal' ? `${defaultMaxW}px` : `${defaultMaxW * 1.3}px`
  const handleSize = size === 'normal' ? 18 : 24
  const { selected, metadata } = node
  const highlight = metadata?.highlight || false
  const dimNode = areNodesSelected && !selected && !highlight
  const opacity = dimNode ? NON_SELECTED_DIMMING_OPACITY : 1

  let borderColor = 'transparent'
  let borderStyle = 'dashed'

  if (selected) {
    borderStyle = 'solid'
    borderColor = selectedNodeColor
  }

  return (
    <Box ref={nodeRef} minW={minW} maxW={maxW} {...rest}>
      {topHandle && (
        <Handle
          type={topHandle.type}
          position={Position.Top}
          style={handleStyles(isEditor, handleSize, colorMode)}
          id="top"
        />
      )}
      {leftHandle && (
        <Handle
          type={leftHandle.type}
          position={Position.Left}
          style={handleStyles(isEditor, handleSize, colorMode)}
          id="left"
        />
      )}
      <Box
        opacity={opacity}
        borderWidth="3px"
        borderStyle={borderStyle}
        borderColor={borderColor}
        borderRadius={node.type === 'commentThread' ? '32px 32px 32px 0' : '9px'}
        bgColor="transparent"
      >
        {children}
      </Box>
      {rightHandle && (
        <Handle
          type={rightHandle.type}
          position={Position.Right}
          style={handleStyles(isEditor, handleSize, colorMode)}
          id="right"
        />
      )}
      {bottomHandle && (
        <Handle
          type={bottomHandle.type}
          position={Position.Bottom}
          style={handleStyles(isEditor, handleSize, colorMode)}
          id="bottom"
        />
      )}
    </Box>
  )
}

export default memo(Node)
