import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import type { FC } from 'react'

import useAutoLayout from '@app/hooks/autolayout/useAutoLayout'
import Can from '@app/shared/authorization/can'
import ICON_MAP from '@app/utils/iconMap'

type Props = {
  strategyId: string
}

const AutoLayoutButton: FC<Props> = ({ strategyId }) => {
  const [runLayout] = useAutoLayout(strategyId, { algorithm: 'elk', direction: 'BT', spacing: [500, 500] })

  return (
    <Can I="view" an="admin">
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Run layout cleanup"
          colorScheme="gray"
          icon={<ICON_MAP.ReLayout />}
          onClick={runLayout}
          size="2xs"
          variant="ghost"
        />
        <MenuList>
          <MenuItem onClick={() => runLayout('dagre')}>Dagre</MenuItem>
          <MenuItem onClick={() => runLayout('d3-hierarchy')}>D3 Hierarchy</MenuItem>
          <MenuItem onClick={() => runLayout('elk')}>Elk</MenuItem>
        </MenuList>
      </Menu>
    </Can>
  )
}

export default AutoLayoutButton
