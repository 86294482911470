import type { IconProps as _ } from '@chakra-ui/react' // TODO: should be handled in tsconfig
import { BiScreenshot } from 'react-icons/bi'
import { CgLinear } from 'react-icons/cg'
import { FaJira } from 'react-icons/fa'
import { LuFileJson } from 'react-icons/lu'
import { MdDisplaySettings } from 'react-icons/md'
import {
  PiArchive,
  PiArrowUpRight,
  PiArrowsOutSimple,
  PiArticle,
  PiCaretDown,
  PiChartDonut,
  PiChartLineUp,
  PiChatCircle,
  PiCoins,
  PiCopy,
  PiCornersOut,
  PiDatabase,
  PiDotsThree,
  PiFootball,
  PiFunnel,
  PiGauge,
  PiGear,
  PiGift,
  PiHardDrives,
  PiImage,
  PiInfo,
  PiLayout,
  PiLineSegments,
  PiMagicWand,
  PiMapTrifold,
  PiMinus,
  PiNote,
  PiNotepadLight,
  PiPaintBrushHouseholdLight,
  PiPencil,
  PiPlus,
  PiProjectorScreenChart,
  PiRoadHorizon,
  PiStar,
  PiStarFill,
  PiTarget,
  PiTrash,
  PiUpload,
  PiUsers,
  PiWrench,
  PiX
} from 'react-icons/pi'
import { SiAsana, SiGooglesheets } from 'react-icons/si'
import { VscExpandAll, VscCollapseAll } from 'react-icons/vsc'

import Productboard from '@app/images/productboard'
import Shortcut from '@app/images/shortcut'

const ICON_MAP = {
  AiPrompt: PiMagicWand,
  Asana: SiAsana,
  BasicCard: PiArticle,
  Bet: PiCoins,
  Entity: PiWrench,
  GoogleSheets: SiGooglesheets,
  Image: PiImage,
  Jira: FaJira,
  Linear: CgLinear,
  Metric: PiGauge,
  Note: PiNote,
  Playbook: PiFootball,
  PlaybookTemplate: LuFileJson,
  Productboard,
  Section: BiScreenshot,
  Shortcut,
  Strategy: PiMapTrifold,
  StrategyMap: PiMapTrifold,
  StrategyReport: PiProjectorScreenChart,
  Work: PiWrench,

  Comments: PiChatCircle,
  Favorite: PiStar,
  Favorited: PiStarFill,

  CardSize: PiArrowsOutSimple,
  CardSettings: MdDisplaySettings,
  Close: PiX,
  ColorAction: PiPaintBrushHouseholdLight,
  Copy: PiCopy,
  Delete: PiTrash,
  Edit: PiPencil,
  Ellipsis: PiDotsThree,
  MoreOptions: PiCaretDown,
  Settings: PiGear,

  CollapseAction: VscCollapseAll,
  ExpandAction: VscExpandAll,

  LabelAction: PiNotepadLight,

  ExternalLink: PiArrowUpRight,
  Team: PiUsers,

  Details: PiInfo,
  Confidence: PiChartDonut,
  Impact: PiChartLineUp,
  Data: PiHardDrives,
  DataSource: PiDatabase,

  Changelog: PiArchive,
  MapEducation: PiGift,
  Roadmap: PiRoadHorizon,
  OKR: PiTarget,

  AddItem: PiPlus,

  ZoomIn: PiPlus,
  ZoomOut: PiMinus,
  FitView: PiCornersOut,
  ReLayout: PiLayout,

  Segment: PiLineSegments,
  Filter: PiFunnel,

  Upload: PiUpload
}

export const COLOR_MAP = {
  AiPrompt: 'blue.600',
  Asana: 'blue.600',
  BasicCard: 'blue.600',
  Bet: 'red',
  Entity: 'orange',
  Image: 'blue.600',
  Jira: 'blue.600',
  Linear: 'blue.600',
  Metric: 'purple',
  Note: 'blue.600',
  Playbook: 'blue.600',
  Productboard: 'blue.600',
  Section: 'blue.600',
  Shortcut: 'blue.600',
  Strategy: 'blue',
  StrategyMap: 'blue.600',
  StrategyReport: 'blue.600',
  Work: 'blue.600',

  Comments: 'blue.600',
  Favorite: 'blue.600',
  Favorited: 'blue.600',

  MoreOptions: 'blue.600',
  Close: 'blue.600',
  ColorAction: 'blue.600',

  CollapseAction: 'blue.600',
  ExpandAction: 'blue.600',

  LabelAction: 'blue.600',

  ExternalLink: 'blue.600',
  Team: 'blue.600',

  // Mapped to colorScheme
  'Confidence-at_risk': 'yellow',
  'Confidence-on_track': 'green',
  'Confidence-off_track': 'red',

  WorkflowStateUnstarted: 'yellow',
  WorkflowStateStarted: 'blue',
  WorkflowStateReviewing: 'teal',
  WorkflowStateFinished: 'green'
}

export default ICON_MAP
