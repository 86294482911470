import type { FC } from 'react'
import { memo } from 'react'

import CommentsButton from './components/commentsButton'
import CommonButtons from './components/commonButtons'
import DataButton from './components/dataButton'
import DetailsButton from './components/detailsButton'
import EventsButton from './components/eventsButton'
import KeyResultsButton from './components/keyResultsButton'
import SettingsButton from './components/settingsButton'
import SourceButton from './components/sourceButton'
import SuggestMetricsButton from './components/suggestMetricsButton'
import Toolbar from './components/toolbar'

import type { MapDomainNode } from '@app/types'

interface Props {
  node: MapDomainNode
}

const MetricToolbar: FC<Props> = ({ node }) => {
  const { strategyId } = node
  const route = `/strategy/${strategyId}/map/metric/${node.data.id}`

  return (
    <Toolbar strategyId={strategyId}>
      <DetailsButton path={route} />
      <SettingsButton path={`${route}/settings`} />
      <DataButton path={`${route}/data`} />
      <SourceButton path={`${route}/source`} />
      <EventsButton path={`${route}/events`} />
      <KeyResultsButton path={`${route}/key_results`} />
      <CommentsButton path={`${route}/comments`} />
      <SuggestMetricsButton strategyId={strategyId} metricId={node.data.id} />
      <CommonButtons node={node} />
    </Toolbar>
  )
}

export default memo(MetricToolbar)
