import type { EdgeProps } from '@xyflow/react'
import { getBezierPath } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

import CorrelationLabel from './components/correlationLabel'
import InsightLabel from './components/insightLabel'
import StyledPath from './components/styledPath'

import useGetObject from '@app/hooks/useGetObject'
import { displayLabel } from '@app/pages/maps/components/edges/helpers'
import { useStore } from '@app/store'

interface Props extends EdgeProps {}

const Edge: FC<Props> = ({
  id,
  markerEnd,
  selected,
  style,
  sourcePosition,
  sourceX,
  sourceY,
  targetPosition,
  targetX,
  targetY,
  labelStyle,
  labelShowBg,
  labelBgStyle,
  labelBgPadding,
  labelBgBorderRadius
}) => {
  const { strategyId } = useParams()
  const strategy = useGetObject(strategyId, 'strategy')
  const { showCorrelations } = strategy

  const getNodeById = useStore.use.getNodeById()
  const edge = useGetObject(id, 'edge')
  const source = getNodeById(edge?.source)
  const target = getNodeById(edge?.target)

  // Do not render if either side is hidden. This kills the Phantom Edge bug
  if (!edge || source?.hidden || target?.hidden) {
    return null
  }

  const { sourceId, targetId } = edge || {}

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  })

  const { displayType, color, selectedColor, label } = displayLabel(edge, source, target, showCorrelations)

  return (
    <>
      <StyledPath
        path={edgePath}
        isSelected={selected}
        style={style}
        color={color}
        selectedColor={selectedColor}
        markerEnd={markerEnd}
      />
      {displayType === 'correlation' && (
        <CorrelationLabel
          correlationScore={label}
          style={style}
          x={labelX}
          y={labelY}
          labelStyle={labelStyle}
          labelShowBg={labelShowBg}
          labelBgStyle={labelBgStyle}
          labelBgPadding={labelBgPadding}
          labelBgBorderRadius={labelBgBorderRadius}
          color={color}
          sourceId={sourceId}
          targetId={targetId}
        />
      )}
      {displayType === 'insight' && (
        <InsightLabel insights={label} x={labelX} y={labelY} labelBgBorderRadius={labelBgBorderRadius} color={color} />
      )}
    </>
  )
}

export default memo(Edge)
