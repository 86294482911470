import { useNodesInitialized } from '@xyflow/react'
import { useMemo } from 'react'

import layoutAlgorithms, { type LayoutAlgorithmOptions } from './algorithms'
import { getSourceHandlePosition, getTargetHandlePosition } from './utils'

import useGetEdges from '@app/hooks/useGetEdges'
import useGetNodes from '@app/hooks/useGetNodes'
import { useStore } from '@app/store'

export type LayoutOptions = {
  algorithm: keyof typeof layoutAlgorithms
} & LayoutAlgorithmOptions

const useAutoLayout = (strategyId, options: LayoutOptions) => {
  const updateObjects = useStore.use.updateObjects()

  // const { setNodes, setEdges } = useReactFlow()
  const nodesInitialized = useNodesInitialized()
  // Here we are storing a map of the nodes and edges in the flow. By using a
  // custom equality function as the second argument to `useStore`, we can make
  // sure the layout algorithm only runs when something has changed that should
  // actually trigger a layout change.
  const storeNodes = useGetNodes(strategyId)
  const storeEdges = useGetEdges(strategyId)

  // This feels like it should go
  const elements = {
    nodes: storeNodes,
    edges: storeEdges
  }

  const runLayout = async (algorithm) => {
    if (!nodesInitialized || elements.nodes.length === 0) {
      return
    }
    const layoutAlgorithm = layoutAlgorithms[algorithm]
    // Pass in a clone of the nodes and edges so that we don't mutate the
    // original elements.
    const nodes = elements.nodes.map((node) => ({ ...node }))
    const edges = elements.edges.map((edge) => ({ ...edge }))

    const { nodes: nextNodes } = await layoutAlgorithm(nodes, edges, options)

    for (const node of nextNodes) {
      node.style = { ...node.style, opacity: 1 }
      node.sourcePosition = getSourceHandlePosition(options.direction)
      node.targetPosition = getTargetHandlePosition(options.direction)
    }

    // This shouldn't be done here but until we settle on an algorithm its a better solution till we can have it configured to return integers
    // create an array of objects with the format { node: { id: node.id, position: { x, y } }.  Ensure all x,y positions are integers
    const objects = nextNodes.map((node) => {
      const updateObject = {
        node: { id: node.nodeId, position: { x: Math.round(node.position.x), y: Math.round(node.position.y) } }
      }
      return updateObject
    })

    updateObjects(objects)
  }

  // runLayout()
  return useMemo(() => [runLayout], [runLayout])
}

export default useAutoLayout
