import { subject } from '@casl/ability'
import { HStack, IconButton, Spacer, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiPower, PiProhibit, PiTrash } from 'react-icons/pi'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import AccountAccessToggleButton from '@app/pages/settings/organization/components/accountAccessToggleButton'
import AccountDeleteButton from '@app/pages/settings/organization/components/accountDeleteButton'
import Can from '@app/shared/authorization/can'
import type { Account } from '@graphql/types'

interface Props {
  account: Account
}

const AccountsListActions: FC<Props> = ({ account }) => {
  const { user } = useStoreCurrentUser()
  const { id } = account

  return (
    <Can I="update" this={subject('account', { id, organizationId: user?.organization?.id })}>
      <HStack spacing={0}>
        <Spacer />
        <AccountAccessToggleButton account={account}>
          <Tooltip label={`${account.disabledAt ? 'Activate' : 'Deactivate'} account`}>
            <IconButton
              color="gray.500"
              aria-label={`${account.disabledAt ? 'Activate' : 'Deactivate'} account`}
              icon={account.disabledAt ? <PiPower /> : <PiProhibit />}
              variant="ghost"
            />
          </Tooltip>
        </AccountAccessToggleButton>
        <AccountDeleteButton account={account}>
          <Tooltip label="Delete account">
            <IconButton color="gray.500" aria-label="Delete accoount" icon={<PiTrash />} variant="ghost" />
          </Tooltip>
        </AccountDeleteButton>
      </HStack>
    </Can>
  )
}

export default AccountsListActions
