import type { TextProps } from '@chakra-ui/react'
import {
  HStack,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'
import { PiCaretDown } from 'react-icons/pi'

import useCookie from '@app/hooks/useCookie'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { METRIC_CARD_TYPE_COOKIE } from '@app/lib/globals'
import cardFontSize from '@app/shared/cards/cardFontSize'
import { SearchList, SearchListItem } from '@app/shared/searchList'
import { useStore } from '@app/store'
import type { CardSize } from '@app/types'
import { COLOR_MAP } from '@app/utils/iconMap'
import { metricDisplayName } from '@app/utils/metricHelpers'
import type { Metric } from '@graphql/types'
import { StrategyTypeEnum } from '@graphql/types'

interface Props extends TextProps {
  metric: Pick<Metric, 'id' | 'strategyType' | 'classType'>
  size?: CardSize
}

const MetricTypePopover: FC<Props> = ({ metric, size = 'normal', ...rest }) => {
  const [, setStrategyType] = useCookie(METRIC_CARD_TYPE_COOKIE)
  const { user } = useStoreCurrentUser()
  const initialFocusRef = useRef()
  const { isOpen, onToggle, onClose } = useDisclosure()
  const updateObject = useStore.use.updateObject()
  const { id, strategyType } = metric
  const cardTypeColor = useColorModeValue(`${COLOR_MAP.Metric}.600`, `${COLOR_MAP.Metric}.300`)
  const fontSize = cardFontSize('md', size)

  const displayType = metricDisplayName(strategyType)

  if (user?.role !== 'editor') {
    return (
      <Text color={cardTypeColor} fontSize={fontSize} {...rest}>
        {displayType}
      </Text>
    )
  }

  const handleItemClick = (value: string) => {
    // Set the METRIC_CARD_TYPE_COOKIE so we can remember the last selected value
    setStrategyType(value)

    updateObject({ metric: { id, strategyType: value as StrategyTypeEnum } })

    onToggle()
  }

  return (
    <Popover initialFocusRef={initialFocusRef} isLazy isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <HStack cursor="pointer" onClick={onToggle}>
          <Text color={cardTypeColor} fontSize={fontSize} {...rest}>
            {displayType}
          </Text>
          <Icon as={PiCaretDown} color={cardTypeColor} />
        </HStack>
      </PopoverTrigger>
      <Portal>
        <PopoverContent maxW={36}>
          <PopoverArrow />
          <PopoverBody m={0} p={0}>
            <SearchList
              searchField="type"
              currentValue={strategyType}
              initialFocusRef={initialFocusRef}
              onChange={handleItemClick}
            >
              <SearchListItem text="KPI" value={StrategyTypeEnum.Kpi} />
              <SearchListItem text="North Star" value={StrategyTypeEnum.NorthStar} />
              <SearchListItem text="Input" value={StrategyTypeEnum.Input} />
              <SearchListItem text="Diagnostic" value={StrategyTypeEnum.Diagnostic} />
            </SearchList>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default MetricTypePopover
