import { Text, Stack, HStack, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

import CommentsCount from '../commentsCount'

import useGetObject from '@app/hooks/useGetObject'
import CardConfidenceRating from '@app/next/domainObjects/confidenceRating/cardConfidenceRating'
import cardFontSize from '@app/shared/cards/cardFontSize'
import { CardAvatar } from '@app/shared/cards/components'
import CardMetricGoals from '@app/shared/cards/metric/cardMetricGoals'
import CardStats from '@app/shared/cards/metric/cardStats'
import type { CardSize, MapDomainMetric } from '@app/types'

type Props = {
  strategyId: string
  metric: MapDomainMetric
  size?: CardSize
}

const CollapsedMetric: FC<Props> = ({ strategyId, metric, size = 'normal' }) => {
  const { goals, ownerId, commentsCount, confidenceRating } = metric
  const owner = useGetObject(ownerId, 'user')
  const goalIds = goals ? goals.map((goal) => goal.id) : []
  const [searchParams] = useSearchParams()
  const fontSize = cardFontSize('md', size)

  const showMetricData = searchParams.get('metricData') !== 'false'

  return (
    <Stack>
      <HStack>
        {confidenceRating && (
          <CardConfidenceRating size={size} type={confidenceRating} fieldName="confidenceRating" collapse />
        )}
        {owner && <CardAvatar user={owner} size={size} />}
        <Text fontSize={fontSize} fontWeight="semibold">
          {metric.name}
        </Text>
        <Spacer />
        <CommentsCount commentsCount={commentsCount} />
      </HStack>
      {goalIds.length > 0 && showMetricData && (
        <CardMetricGoals metric={metric} goalIds={goalIds} size={size} showTitle={false} />
      )}
      {showMetricData && <CardStats strategyId={strategyId} metric={metric} size={size} />}
    </Stack>
  )
}

export default CollapsedMetric
